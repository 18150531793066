<template>
  <div>
    <!--滚动表格-->
    <section style="height: 265px; overflow: hidden">
      <!--增加一个表头-->
      <el-table class="hidden-tbody" style="width: 100%; margin-top: 15px">
        <el-table-column prop="id" label="#" width="20px"></el-table-column>
        <el-table-column
          prop="name"
          label="站点名称"
          width="120px"
        ></el-table-column>
        <el-table-column prop="online" label="站点状态"></el-table-column>
        <el-table-column prop="status" label="运行状态"></el-table-column>
        <el-table-column
          prop="totalChargeNum"
          label="累计充电量"
        ></el-table-column>
        <el-table-column
          prop="totalDischargeNum"
          label="累计放电量"
        ></el-table-column>
        <el-table-column prop="soc" label="SOC" width="60px"></el-table-column>
        <el-table-column prop="soc" label="效率" width="80px"></el-table-column>
      </el-table>
      <!--滚动的列表-->
      <div class="scroll">
        <vue-seamless-scroll
          :data="cols"
          class="auto-scorll-table"
          :class-option="classOption"
        >
          <el-table
            :data="cols"
            class="custom-table-2 hidden-thead mytable_header_no"
            :show-header="false"
          >
            <el-table-column prop="id" label="" width="20px">
              <template v-slot="{ $index }">
                <div>
                  {{ $index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="" width="120px">
              <template v-slot="{ row }">
                <div @click="toDevice(row)" style="cursor: pointer">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="online" label="">
              <template slot-scope="scope">
                <div
                  v-if="scope.row.online == '在线'"
                  class="flex items-middle"
                >
                  <div class="point bg-green"></div>
                  <span style="padding-left: 5px">在线</span>
                </div>
                <div
                  v-else-if="scope.row.online == '离线'"
                  class="flex items-middle"
                >
                  <div class="point bg-red"></div>
                  <span style="padding-left: 5px">离线</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="">
              <template slot-scope="scope">
                <div
                  v-if="scope.row.status == '运行'"
                  class="flex items-middle"
                >
                  <div class="point bg-green"></div>
                  <span style="padding-left: 5px">运行</span>
                </div>
                <div
                  v-else-if="scope.row.status == '空闲'"
                  class="flex items-middle"
                >
                  <div class="point bg-yellow"></div>
                  <span style="padding-left: 5px">空闲</span>
                </div>
                <div
                  v-else-if="scope.row.status == '故障'"
                  class="flex items-middle"
                >
                  <div class="point bg-red"></div>
                  <span style="padding-left: 5px">故障</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="totalChargeNum" label=""></el-table-column>
            <el-table-column
              prop="totalDischargeNum"
              label=""
            ></el-table-column>
            <el-table-column prop="soc" label="" width="60px"></el-table-column>
            <el-table-column
              prop="efficiency"
              label=""
              width="80px"
            ></el-table-column>
          </el-table>
        </vue-seamless-scroll>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格数据
      tableData: [],
    };
  },
  props: {
    cols: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  // 表格高度设置
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 44, // 这里要变成你的一行表格的高度 可以审查元素
      };
    },
    classOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.list
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    toDevice(row) {
      this.$router.push({
        name: "SiteDetail",
        query: {
          id: row.siteId,
          rateId: 1,
          siteName: row.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-tbody.el-table {
  height: 48px;
  box-sizing: border-box;
  tbody {
    //隐藏上面表格的tbody
    display: none;
    overflow: hidden;
  }
}
.auto-scorll-table {
  height: calc(100% - 34px);
  overflow: hidden;
}
.hidden-thead.el-table {
  border-top: none; //防止边框重叠
  thead {
    //隐藏下面表格的thead
    display: none;
    overflow: hidden;
  }
}
.mytable_header .el-table__empty-block {
  display: none;
}
.mytable_header_no .has-gutter {
  display: none;
}

/deep/ .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.bg-green {
  background-color: #00cc99;
}
.bg-red {
  background-color: #d9001b;
}
.bg-yellow {
  background-color: #ffc328;
}

/deep/ .el-table th.el-table__cell {
  background: #2c3853;
  font-weight: 700;
}
/deep/ .el-table tr:nth-child(odd) {
  background: #222e4a;
}
/deep/ .el-table tr:nth-child(even) {
  background: #26324e;
}
/deep/ .el-table th.el-table__cell.is-leaf {
  border: 0px !important;
}
</style>
