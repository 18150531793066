<template>
  <div class="home-box">
    <div class="top flex">
      <div class="site-distribution">
        <div class="title">
          <lable-name name="站点分布"></lable-name>
        </div>
        <div class="site-distribution-content flex">
          <div class="site-total-val">
            <div class="total-val" v-for="(v, i) in statisticalList" :key="i">
              <icon-title
                :imgSrc="v.src"
                :title="v.title"
                :value="v.value"
                :unit="v.unit"
              ></icon-title>
            </div>
          </div>
          <div class="site-map">
            <Map :mapData="mapData"></Map>
          </div>
        </div>
      </div>
      <div class="site-info">
        <div class="title">
          <lable-name name="站点信息"></lable-name>
        </div>
        <div class="site-info-content">
          <div class="site-bar">
            <row-bar :siteData="siteData"></row-bar>
            <!-- <bar-1></bar-1> -->
          </div>
          <div class="site-list">
            <table-auto-scroll :cols="siteData"></table-auto-scroll>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title-box flex flex-start items-middle">
        <div class="title">
          <lable-name name="收益及电量信息"> </lable-name>
        </div>
        <div class="date-select">
          <date-select
            @getChartData="getChartData"
            @getRecentData="getRecentData"
          ></date-select>
        </div>
      </div>
      <div class="bottom-box flex">
        <div class="chart">
          <single-bar :xData="listEarnings" yName1="收益（元）"> </single-bar>
        </div>
        <div class="chart">
          <double-bar
            :xData="listDisOrCharge"
            yName1="充电量（kWh）"
            yName2="放电量（kWh）"
          ></double-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LableName from "../../components/LableName.vue";
import { siteDistributionInfo, siteStatisticsInfo } from "@/api/home.js";
import IconTitle from "../../components/iconTitle.vue";
import Map from "./components/map.vue";
import TableAutoScroll from "./components/tableAutoScroll.vue";
import RowBar from "./components/rowBar.vue";
import DoubleBar from "../../components/doubleBar.vue";
import DateSelect from "../../components/dateSelect.vue";
import SingleBar from "../../components/singleBar.vue";

export default {
  components: {
    LableName,
    IconTitle,
    Map,
    TableAutoScroll,
    RowBar,
    DoubleBar,
    DateSelect,
    SingleBar,
  },
  data() {
    return {
      siteData: [],
      statisticalList: [
        {
          title: "累计收益",
          value: 0,
          unit: "元",
          src: require("@/assets/imgs/u151.svg"),
        },
        {
          title: "本月收益",
          value: 0,
          unit: "元",
          src: require("@/assets/imgs/u166.svg"),
        },
        {
          title: "总充电量",
          value: 0,
          unit: "kWh",
          src: require("@/assets/imgs/u176.svg"),
        },
        {
          title: "总放电量",
          value: 0,
          unit: "kWh",
          src: require("@/assets/imgs/u171.svg"),
        },
        {
          title: "总装机容量",
          value: 0,
          unit: "kWh",
          src: require("@/assets/imgs/u161.svg"),
        },
        {
          title: "总装机功率",
          value: 0,
          unit: "kW",
          src: require("@/assets/imgs/u156.svg"),
        },
      ],
      chartData: {
        incomeTime: [
          "2022-07-08",
          "2022-07-09",
          "2022-07-10",
          "2022-07-11",
          "2022-07-12",
          "2022-07-13",
          "2022-07-14",
        ],
        income: [500, 600, 520, 530, 510, 700, 410],
        chargeTime: [
          "2022-07-08",
          "2022-07-09",
          "2022-07-10",
          "2022-07-11",
          "2022-07-12",
          "2022-07-13",
          "2022-07-14",
        ],
        charge: [120, 160, 120, 300, 400, 450, 111],
        discharge: [120, 400, 450, 111, 160, 120, 300],
        // siteInfo:[]
      },
      homeData: {},
      mapData: [],
      sortData: [],
      listEarnings: [],
      listDisOrCharge: [],
      timer: null,
    };
  },
  mounted() {
    this.getData();
    this.getRecentData();
    this.timer = setInterval(() => {
      setTimeout(() => {
        this.getData();
      }, 0);
    }, 60000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
      this.timer = null;
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    getData() {
      siteDistributionInfo().then((res) => {
        // 收益计算
        if (res.data.data.accumulatedEarnings > 10000) {
          this.statisticalList[0].value =
            res.data.data.accumulatedEarnings / 10000;
          this.statisticalList[0].unit = "万元";
        } else {
          this.statisticalList[0].value = res.data.data.accumulatedEarnings;
        }
        if (res.data.data.curMonthEarnings > 10000) {
          this.statisticalList[1].value =
            res.data.data.curMonthEarnings / 10000;
          this.statisticalList[1].unit = "万元";
        } else {
          this.statisticalList[1].value = res.data.data.curMonthEarnings;
        }
        if (res.data.data.chargeNum > 1000) {
          this.statisticalList[2].value = res.data.data.chargeNum / 1000;
          this.statisticalList[2].unit = "MWh";
        } else {
          this.statisticalList[2].value = res.data.data.chargeNum;
        }
        if (res.data.data.disChargeNum > 1000) {
          this.statisticalList[3].value = res.data.data.disChargeNum / 1000;
          this.statisticalList[3].unit = "MWh";
        } else {
          this.statisticalList[3].value = res.data.data.disChargeNum;
        }
        this.statisticalList[4].value = res.data.data.installedCapacity;
        this.statisticalList[5].value = res.data.data.installedPower;
        this.siteData = res.data.data.siteInfo.sort(
          this.handleCompare("utilizationRate")
        );
        this.siteData.forEach((v) => {
          let obj = {
            name: v.address.split(",")[0],
            value: 1,
          };
          let flag = true;
          this.mapData.forEach((j) => {
            if (obj.name == j.name) {
              j.value++;
              flag = false;
            }
          });
          if (flag) {
            this.mapData.push(obj);
          }
        });
      });
    },
    getRecentData() {
      siteStatisticsInfo({ dayNum: 7 }).then((res) => {
        let listEarnings = res.data.data.listEarnings;
        listEarnings.forEach((v, i) => {
          v.key = v.key.substr(5, 5);
        });
        this.listEarnings = listEarnings;

        let listDisOrCharge = res.data.data.listDisOrCharge;
        listDisOrCharge.forEach((v) => {
          v.key = v.key.substr(5, 5);
        });
        this.listDisOrCharge = listDisOrCharge;
      });
    },
    handleCompare(property) {
      return (a, b) => {
        let s1 = a[property];
        let s2 = b[property];
        console.log(typeof s1);
        let v1 = s1.substr(0, s1.length - 1);
        let v2 = s2.substr(0, s2.length - 1);
        const value1 = Number(v1);
        const value2 = Number(v2);
        return value2 - value1;
      };
    },
    getChartData(activeBtn, value) {
      if (activeBtn == 1) {
        siteStatisticsInfo({ monthNum: value }).then((res) => {
          let listEarnings = res.data.data.listEarnings;
          listEarnings.forEach((v, i) => {
            v.key = v.key.substr(8, 2);
          });
          this.listEarnings = listEarnings;

          let listDisOrCharge = res.data.data.listDisOrCharge;
          listDisOrCharge.forEach((v) => {
            v.key = v.key.substr(8, 2);
          });
          this.listDisOrCharge = listDisOrCharge;
        });
      } else if (activeBtn == 2) {
        siteStatisticsInfo({ yearNum: value }).then((res) => {
          let listEarnings = res.data.data.listEarnings;
          listEarnings.forEach((v, i) => {
            v.key = v.key.substr(5, 2);
          });
          this.listEarnings = listEarnings;

          let listDisOrCharge = res.data.data.listDisOrCharge;
          listDisOrCharge.forEach((v) => {
            v.key = v.key.substr(5, 2);
          });
          this.listDisOrCharge = listDisOrCharge;
        });
      } else if (activeBtn == 0) {
        this.getRecentData();
      }
    },
    uniqueFunc(arr, uniId) {
      const res = new Map();
      return arr.filter(
        (item) => !res.has(item[uniId]) && res.set(item[uniId], 1)
      );
    },
    setClass(key) {
      console.log(key);
      let obj = this.statisticalList[key].class;
      return obj;
    },
  },
  filters: {
    num: (val, fix = 2) => {
      if (val < 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = Math.abs(val);
        val = "" + val; // 转换成字符串
        console.log("llllll", val);
        var int = val.slice(0, fix * -1 - 1); // 拿到整数
        var ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        var temp = ""; // 临时变量
        for (var i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return "-" + temp; // 返回
      } else if (val != "-" && val != "" && val != null && val > 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = "" + val; // 转换成字符串
        int = val.slice(0, fix * -1 - 1); // 拿到整数
        ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        temp = ""; // 临时变量
        for (i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return temp; // 返回
      } else if (val == 0 || val == "0") {
        return val;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-box {
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  display: inline-block;
  background: #002140;
  min-width: 1200px;
  //background: #fff;
  padding-bottom: 20px;
  .top {
    width: 100%;
    //height: 680px;
    height: 620px;
    .site-distribution {
      //width: 1050px;
      width: 60%;
      background: #222e4a;
      margin: 10px;
      .site-distribution-content {
        padding: 10px;
        .site-total-val {
          padding-left: 20px;
          margin-top: -20px;
          .total-val {
            margin: 40px 10px;
          }
        }
        .site-map {
          width: 60%;
          height: 620px;
          //margin: 0 20px;
        }
      }
    }
    .site-info {
      width: 40%;
      background: #222e4a;
      margin: 10px 10px 10px 0;
      .site-info-content {
        height: 100%;
        .site-bar {
          height: 300px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px 10px 10px;
    margin-bottom: 10px;
    .title-box {
      background: #222e4a;
      height: 50px;
      .date-select {
        width: 80%;
      }
    }
    .bottom-box {
      width: 100%;
      height: 380px;
      background: #222e4a;
      padding: 20px 0;
      .chart {
        padding: 10px;
        width: 70%;
        box-sizing: border-box;
        position: relative;
      }
    }
  }
}
</style>
