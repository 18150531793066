<template>
  <!-- <div class="row-bar-box"> -->
  <div class="row">
    <div class="tooltip-box flex items-middle flex-center">
      <div class="tooltip flex items-middle">
        <div class="cube"></div>
        <div class="title" style="padding-left: 10px">站点累计资产利用率</div>
      </div>
    </div>

    <vue-seamless-scroll
      :class-option="optionSetting"
      :data="dataSource"
      class="wrap"
    >
      <ul>
        <li
          :key="index"
          v-for="(item, index) in siteData"
          style="margin: 10px 0"
        >
          <div class="r1">
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="bar-box flex space-between items-middle">
            <div
              class="bar1"
              v-bind:style="{ width: item.utilizationRate + '%' }"
            ></div>
            <div class="text-box">
              <div class="text">
                {{ item.utilizationRate + "%" }}
              </div>
            </div>
          </div>
          <div class="bar2"></div>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  props: {
    siteData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dataSource: [
        {
          name: "0000",
          sal: 90,
        },
        {
          name: "0000",
          sal: 90,
        },
        {
          name: "0000",
          sal: 90,
        },
        {
          name: "0000",
          sal: 90,
        },
        {
          name: "0000",
          sal: 90,
        },
      ],
      max: 100,
      optionSetting: {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        //singleHeight: 50, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  watch: {
    siteData(newVal) {
      this.siteData = newVal;
      this.optionSetting.limitMoveNum = 3;
    },
  },
  method: {
    initData(data) {
      //写从后端拿数据的过程，将拿到的数据存在data中，这个initData方法是要在其他地方拿数据的哦，这两个值自己取
      this.dataSource = data;
      this.max = 100; //最大值，用来将最大值设置为进度条满时的值
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-left: 12px;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  // background: rgba(60, 114, 168, 0.2);
  //border: 1px solid rgba(96, 204, 255, 0.3);
  border-radius: 4px;
  .wrap {
    height: 90%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .bar1 {
      margin-top: 8px;

      background-image: linear-gradient(-90deg, #42edff, #0d84ff);
      height: 8px;
      width: 100px;
      border-radius: 0 10px 10px 0;
      z-index: 2;
    }
    .bar2 {
      color: #fff;
      height: 8px;
      width: 85%;
      background-color: #cccccc;
      opacity: 0.1;
      position: relative;
      top: -8px;
      border-radius: 0 10px 10px 0;
    }
  }
}
.r1 {
  margin-top: 0;
  padding-top: 0;
  height: 25px;
}
ul,
li {
  //把ul和li的样式初始化
  list-style-type: none;
  margin-left: 0px;
  padding: 0px 0px 0px 10px;
}
.tooltip-box {
  width: 100%;
  margin-bottom: 10px;
}
.cube {
  width: 12px;
  height: 12px;
  background-image: linear-gradient(-90deg, #42edff, #0d84ff);
  //background-image: linear-gradient(to left, #01feff, transparent);
}
.bar-box {
  width: 85%;
  .text-box {
    color: #fff;
    font-size: 12px;
    position: relative;
    .text {
      top: -2px;
      position: absolute;
      left: 5px;
    }
  }
}
</style>
