<template>
  <div class="echartsMapAllDemo">
    <!-- style的宽高一定要写，不写也不会展示echarts图 -->
    <div
      id="myEcharts"
      ref="myEcharts"
      style="
        width: 133%;
        height: 118%;
        position: absolute;
        left: -15px;
        top: -120px;
      "
    ></div>
  </div>
</template>
<script>
import chinaJson from "@/echarts/map/json/china.json";
export default {
  props: {
    mapData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    mapData() {
      this.chinaEcharts();
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.chinaEcharts();
  },
  methods: {
    chinaEcharts() {
      this.$echarts.registerMap("china", chinaJson);
      let myChart = this.$echarts.init(this.$refs.myEcharts);
      var data = this.mapData;
      var geoCoordMap = {
        上海: [121.472644, 31.231706],
        云南: [102.712251, 25.040609],
        内蒙古: [111.670801, 40.818311],
        北京: [116.405285, 39.904989],
        台湾: [121.509062, 25.044332],
        吉林: [125.3245, 43.886841],
        四川: [104.065735, 30.659462],
        天津: [117.190182, 39.125596],
        宁夏: [106.278179, 38.46637],
        安徽: [117.283042, 31.86119],
        山东: [117.000923, 36.675807],
        山西: [112.549248, 37.857014],
        广东: [113.280637, 23.125178],
        广西: [108.320004, 22.82402],
        新疆: [87.617733, 43.792818],
        江苏: [118.767413, 32.041544],
        江西: [115.892151, 28.676493],
        河北: [114.502461, 38.045474],
        河南: [113.665412, 34.757975],
        浙江: [120.153576, 30.287459],
        海南: [110.33119, 20.031971],
        湖北: [114.298572, 30.584355],
        湖南: [112.982279, 28.19409],
        澳门: [113.54909, 22.198951],
        甘肃: [103.823557, 36.058039],
        福建: [119.306239, 26.075302],
        西藏: [91.132212, 29.660361],
        贵州: [106.713478, 26.578343],
        辽宁: [123.429096, 41.796767],
        重庆: [106.504962, 29.533155],
        陕西: [108.948024, 34.263161],
        青海: [101.778916, 36.623178],
        香港: [114.173355, 22.320048],
        黑龙江: [126.642464, 45.756967],
      };
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          console.log(geoCoord, "geoCoord", i, data[i]);
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              // value: data[i].value,
            });
          }
        }
        console.log(res);
        return res;
      };

      let option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
          formatter: function (params) {
            console.log(params);
            let tipHtml =
              '<div style="width:150px;height:80px;background:#091736;border:none">' +
              '<div style="width:100%;height:40px;line-height:40px;padding:0 20px;box-sizing:border-box;">' +
              '<i style="display:inline-block;width:8px;height:8px;background:#091736;border-radius:40px;">' +
              "</i>" +
              '<span style="margin-left:30px;color:#fff;font-size:16px;">' +
              params.name +
              "</span>" +
              "</div>" +
              '<div style="padding:0px">' +
              '<p style="color:#fff;font-size:12px;">' +
              '<i style="display:inline-block;width:10px;height:10px;background:#f4e925;border-radius:40px;margin:0 12px">' +
              "</i>" +
              "站点数：" +
              '<span style="color:#11ee7d;margin:0 20px 0 15px">' +
              params.data.value[2] +
              "</span>" +
              "个" +
              "</p>";
            "</div>" + "</div>";
            return tipHtml;
          },
        },
        //这个属性配置一定要有，要不然无法实现光圈效果
        geo: {
          show: true,
          map: "china",
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#222e4a",
              borderColor: "#2ccedf",
            },
            emphasis: {
              areaColor: "#2B91B7",
            },
          },
        },
        //series就是地图上的数据（去掉就没有数据了）
        series: [
          //所有的市
          {
            name: "站点数",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(data),
            symbolSize: function (val) {
              return val[2] * 5;
            },
            //这几个样式一定要加，加上才有那种光圈的效果
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,

            label: {
              formatter: "{b}",
              position: "right",
              show: true,
            },
            //圆点的样式
            itemStyle: {
              // color: "#ddb926",
              color: "#c8cb12",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        console.log("map resize");
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.echartsMapAllDemo {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
